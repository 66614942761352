import * as Noodl from '@noodl/noodl-sdk';
import { useEffect } from 'react';
import { MODULE_NAMESPACE } from '../constants';
var NODE_NAME = "".concat(MODULE_NAMESPACE, ".analyticsLoader");
function InitializerComponent(_a) {
    var measurementId = _a.measurementId, allowTracking = _a.allowTracking;
    useEffect(function () {
        var _a;
        if ((_a = window.history) === null || _a === void 0 ? void 0 : _a.pushState) {
            var pushState_1 = window.history.pushState.bind(history);
            var pushstateEvent_1 = new Event('pushstate');
            // hacking pushstate to send custom event
            window.history.pushState = function (state, title, url) {
                pushState_1(state, title, url);
                window.dispatchEvent(pushstateEvent_1);
            };
        }
    }, []);
    useEffect(function () {
        var _a;
        if (measurementId && allowTracking) {
            var script = document.createElement('script');
            script.src = "https://www.googletagmanager.com/gtag/js?id=".concat(measurementId);
            document.head.appendChild(script);
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () {
                window.dataLayer.push(arguments);
            };
            window.gtag('js', new Date());
            window.gtag('config', measurementId);
            // Listen to push state events and send tracking
            if ((_a = window.history) === null || _a === void 0 ? void 0 : _a.pushState) {
                //this is event is manually sent on push, so covers both pop and push state
                window.addEventListener('pushstate', sendPageView);
            }
        }
        return function () {
            window.removeEventListener('pushstate', sendPageView);
        };
    }, [measurementId, allowTracking]);
    function sendPageView() {
        window.gtag('event', 'page_view', {
            page_title: document.title,
            page_location: window.location.href,
            send_to: measurementId,
        });
    }
    return null;
}
export var GoogleAnalyticsInitializer = Noodl.defineReactNode({
    name: NODE_NAME,
    displayName: 'Google Analytics Root',
    docs: 'https://docs.noodl.net/#/modules/google-analytics/nodes/google-analytics-root/README.md',
    category: 'visual',
    getReactComponent: function () {
        return InitializerComponent;
    },
    inputs: {
        measurementId: {
            displayName: 'Measurement ID',
            type: 'string',
            default: undefined,
            group: 'Analytics',
        },
        allowTracking: {
            displayName: 'Allow Tracking',
            type: 'boolean',
            default: false,
            group: 'Analytics',
        },
    },
    methods: {
        _updateTrackingSignalIfAllowed: function () {
            if (this.props.allowTracking && this.props.measurementId) {
                this.sendSignalOnOutput('trackingStarted');
            }
        },
    },
    changed: {
        allowTracking: function (val) {
            this.props.allowTracking = val;
            this._updateTrackingSignalIfAllowed();
            this.forceUpdate();
        },
        measurementId: function (val) {
            this.props.measurementId = val;
            this._updateTrackingSignalIfAllowed();
            this.forceUpdate();
        },
    },
    outputProps: {
        trackingStarted: {
            type: 'signal',
            displayName: 'Starting Tracking',
            group: 'Analytics',
        },
    },
    // @ts-ignore
    mountedInput: false,
});
